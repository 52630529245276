import { createAction, props } from '@ngrx/store';

import { DashboardDocumentReorganizeCategoryRequest, DashboardDocumentSummaryData, SelectAllData } from '../../../../../core/models';
import { DialogToasterData } from '../../../../sfx-dialog/state/dialog-toaster-data';

// ***** Import Document Actions *****
export const loadDocumentToImport = createAction('[dDocumentsDialog] load documents to import');
export const loadMoreDocumentToImport = createAction('[dDocumentsDialog] load more documents to import');
export const loadDocumentToImportFail = createAction('[dDocumentsDialog] load documents to import fail', props<DialogToasterData>());
export const loadDocumentToImportSuccess = createAction(
  '[dDocumentsDialog] load documents to import success',
  props<{ documents: DashboardDocumentSummaryData[]; totalCount: number; filteredTotalCount: number; disabledFilteredTotalCount: number; reset?: boolean }>(),
);

export const loadReorganizeDocuments = createAction('[dDocumentsDialog] load reorganize documents');
export const loadMoreReorganizeDocument = createAction('[dDocumentsDialog] load more reorganize documents');
export const loadReorganizeDocumentsFail = createAction('[dDocumentsDialog] load reorganize documents fail', props<DialogToasterData>());
export const loadReorganizeDocumentsSuccess = createAction(
  '[dDocumentsDialog] load reorganize documents success',
  props<{ documents: DashboardDocumentSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);

export const refreshDocument = createAction('[dDocumentsDialog] refresh documents', props<{ uuidEntity: string[] }>());
export const refreshDocumentSuccess = createAction('[dDocumentsDialog] refresh document success');
export const refreshDocumentFail = createAction('[dDocumentsDialog] refresh documents fail', props<DialogToasterData>());

export const refreshAllDocuments = createAction('[dDocumentsDialog] refresh all documents');
export const refreshAllDocumentsSuccess = createAction('[dDocumentsDialog] refresh all documents success');
export const refreshAllDocumentsFail = createAction('[dDocumentsDialog] refresh all documents fail', props<DialogToasterData>());

export const importDocuments = createAction('[dDocumentsDialog] import documents', props<{ selectAllData: SelectAllData }>());
export const importDocumentsSuccess = createAction('[dDocumentsDialog] import documents success');
export const importDocumentsFail = createAction('[dDocumentsDialog] import documents fail', props<DialogToasterData>());

export const openFilterDatePopover = createAction('[dDocumentsDialog/popover] open reorganize filter date popover', props<{ origin: HTMLElement }>());

export const reorganizeDocuments = createAction('[dDocumentsDialog] reorganize documents', props<{ reorganizeData: DashboardDocumentReorganizeCategoryRequest; count: number }>());
export const reorganizeDocumentsSuccess = createAction('[dDocumentsDialog] reorganize documents success');
export const reorganizeDocumentsFail = createAction('[dDocumentsDialog] reorganize documents fail', props<DialogToasterData>());
