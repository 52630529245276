import { createAction, props } from '@ngrx/store';

import { LazyLoadResponse } from '../../../../../core/models';
import { DocumentClientFeedbackData } from '../../../../../core/models/document/document-client-feedback';
import { DocumentCollaboratorsData } from '../../../../../core/models/document/document-collaborators';
import { DocumentDetailsData } from '../../../../../core/models/document/document-details';
import { DocumentDiffusionData } from '../../../../../core/models/document/document-diffusion';
import { DocumentVersionDetailsData } from '../../../../../core/models/document/document-version-details';

import { InnerSortDirectionEnum, ToasterTypeEnum } from '../../../../../core/enums';
import { DocumentModuleEnum } from '../../../../../core/enums/document/document-module.enum';

// ***** documents version actions ******
export const loadDocumentVersions = createAction('[Doc-version] load document version', props<{ docUuidEntity: string }>());
export const loadNextDocumentVersionsPage = createAction('[Doc-version] load next document version page', props<{ docUuidEntity: string }>());
export const loadDocumentVersionsSuccess = createAction(
  '[Doc-version] load document version success',
  props<{ documentsVersion: LazyLoadResponse<DocumentVersionDetailsData[]>; reset?: boolean }>(),
);
export const loadDocumentVersionsFail = createAction('[Doc-version] load document version fail', props<{ toasterType?: ToasterTypeEnum; title?: string; subtitle?: string }>());

export const viewLibraryDocumentVersionPdf = createAction(
  '[Doc-version] view document version',
  props<{ docVersionUuidEntity: string; documentData: DocumentDetailsData; fileLoading?: boolean; activeModule?: DocumentModuleEnum }>(),
);
export const downloadLibraryDocumentVersionPdf = createAction(
  '[Doc-version] download document version',
  props<{ documentFlow: DocumentDetailsData; docVersionUuidEntity: string }>(),
);
export const downloadDocumentVersionResponse = createAction('[Doc-version] download document version response', props<{ docVersionUuidEntity: string }>());
export const generateLibraryDocumentVersionPdf = createAction(
  '[Doc-version] generate document version',
  props<{ docVersionUuidEntity: string; fileLoading?: boolean; documentData?: DocumentDetailsData; projectUuidEntity?: string }>(),
);

// Info: global action to trait all diffusion actions.
export const updateDocumentVersionSuccess = createAction('[Doc-version] diffuse document version success', props<{ docVersion?: DocumentVersionDetailsData }>());
export const updateDocumentVersionFail = createAction('[Doc-version] diffuse document version fail', props<{ toasterType?: ToasterTypeEnum; title?: string; subtitle?: string }>());

// Info: Diffusion Actions:
export const convertToBpa = createAction('[Doc-version] Convert document version state to bpa', props<{ docVersionUuidEntity: string }>());
export const convertToBpeValid = createAction(
  '[Doc-version] Convert document version state to bpe valid',
  props<{ diffusionData: DocumentDiffusionData; docVersionUuidEntity: string; file?: File }>(),
);
export const unlockDocumentVersion = createAction(
  '[Doc-version] Unlock document version',
  props<{ docVersionUuidEntity: string; sameIndex: boolean; confirm?: boolean; bpoValue?: string }>(),
);
export const addSubIndex = createAction('[Doc-version] add sub index version', props<{ documentFlowUuidEntity: string; sameIndex: boolean }>());
export const unlockVdfSuccess = createAction(
  '[Doc-version] Unlock document version success',
  props<{ docVersions: DocumentVersionDetailsData.ClientResponseData; sortDirection: InnerSortDirectionEnum }>(),
);
export const diffuseDocument = createAction('[Doc-version] Diffuse document version', props<{ diffusionData: DocumentDiffusionData }>());
export const diffuseBpa = createAction('[Doc-version] Diffuse bpa document version', props<{ diffusionData: DocumentDiffusionData }>());
export const editDiffusionFree = createAction('[Doc-version] Edit diffusion docVersion free', props<{ diffusionData: DocumentDiffusionData; docVersionUuidEntity: string }>());

export const clientResponse = createAction(
  '[Doc-version] client response',
  props<{ clientResponse: DocumentClientFeedbackData; docVersionUuidEntity: string; coverFile: File; docFile: File }>(),
);
export const clientResponseSuccess = createAction(
  '[Doc-version] client response success',
  props<{ docVersions: DocumentVersionDetailsData.ClientResponseData; sortDirection: InnerSortDirectionEnum }>(),
);
export const clientResponseBpa = createAction(
  '[Doc-version] client response bpa',
  props<{ clientResponse: DocumentClientFeedbackData; docVersionUuidEntity: string; coverFile: File; docFile: File }>(),
);
export const updateClientResponse = createAction(
  '[Doc-version] update client response',
  props<{ clientResponse: DocumentClientFeedbackData; docVersionUuidEntity: string; coverFile: File; docFile: File }>(),
);

export const cancelDiffusion = createAction('[Doc-version] cancel diffusion', props<{ docVersionUuidEntity: string }>());
export const cancelDiffusionBpa = createAction('[Doc-version] cancel diffusion bpa', props<{ docVersionUuidEntity: string }>());
export const particularization = createAction(
  '[Doc-version] update complement reference',
  props<{ docUuidEntity: string; docVersionUuidEntity: string; docVersion: DocumentVersionDetailsData; complementRefExt: string; complementRefInt: string }>(),
);

export const deleteLastDocumentVersion = createAction('[Doc-version] delete documents version', props<{ document: DocumentDetailsData }>());
export const deleteLastDocumentVersionSuccess = createAction(
  '[Doc-version] delete documents version success',
  props<{ docVersions: DocumentVersionDetailsData.ClientResponseData }>(),
);

// Info: Upload document version files:
export const uploadDocVersionFile = createAction('[Doc-version] upload vdf file', props<{ file: File; docVersionUuidEntity: string }>());
export const updateDocVersionCollaborators = createAction(
  '[Doc-version] Update document collaborators',
  props<{ docCollaborator: DocumentCollaboratorsData; docVersionUuidEntity: string }>(),
);

// refresh contributors
export const refreshContributors = createAction('[Doc-version] refresh linked contributors', props<{ docVersionUuidEntity: string }>());

// particular case for downloading pdf, a check for new version should be performed
export const checkAndGenerateDoc = createAction(
  '[Doc-version] check and generate document version',
  props<{ docVersionUuidEntity: string; documentData: DocumentDetailsData; fileLoading?: boolean; activeModule?: DocumentModuleEnum }>(),
);

export const refreshFromConfig = createAction('[Doc-version] refresh from config', props<{ docVersionUuidEntity: string }>());
export const refreshFromLastIndex = createAction('[Doc-version] refresh from document last index', props<{ docVersionUuidEntity: string }>());
