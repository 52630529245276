import { compact, isEmpty } from 'lodash';
import { MultiSelectItemData, SingleSelectItemData } from '../../../modules/shared/components/select-v2/utils/select-item-data';

import { QuestionTypeEnum } from '../../enums';
import {
  OptionSignatoryDetailsResponseDTO,
  OptionSignatoryUpdateRequestDTO,
  OptionUpdateRequestDTO,
  RubricOptionDetailsResponseDTO,
  VersionRubricOptionRequestDTO,
} from '../../generated/models/models';
import { DateUtils } from '../../utils/date.utils';
import { ApplicationFileLightData } from '../application-file/application-file';
import { VersionRubricOptionRequestData } from '../option/version-option-request.data';
import { OptionAlertData } from './option-alert-data';
import { OptionRedirectionData } from './option-redirection-data';
import { OptionValidationData } from './option-validation-data';
import { QuestionContributorData } from './question-contributor-data';
import { QuestionDurationData } from './question-duration-data';
import { QuestionLocationData } from './question-location-data';

export type optionValueType = string | string[] | boolean | ApplicationFileLightData[] | QuestionLocationData | QuestionContributorData | QuestionDurationData;
export type questionOptionResponseType = OptionSignatoryDetailsResponseDTO & RubricOptionDetailsResponseDTO;

export interface QuestionOptionData<T> {
  value: T; // INFO: can be: string, boolean, {longitude: string, altitude: string, latitude: string}, contributor
  nodeOrder: number;
  uuidEntity: string;
  name?: string;
  description?: string;
  placeholder?: string;
  disabled?: boolean;
  locked?: boolean;
  choices?: (SingleSelectItemData<string> | MultiSelectItemData)[];
  alertData?: OptionAlertData;
  validationData?: OptionValidationData;
  redirectionData?: OptionRedirectionData;
  unitValue?: string;
  applicationFiles?: ApplicationFileLightData[];
  validity?: boolean;
  fake?: boolean;
  canEvaluate?: boolean;
  evaluatedValue?: string;
}
export namespace QuestionOptionData {
  export const comparePredicate = (node: SingleSelectItemData<string>, item: string) => node.value === item;

  export function mapFromApiValue(option: questionOptionResponseType, questionType: QuestionTypeEnum): QuestionOptionData<optionValueType> {
    return {
      value: getOptionValue(option, questionType),
      nodeOrder: option.optionOrder,
      uuidEntity: option.uuidEntity,
      name: option.name,
      description: option.description,
      disabled: option.checkedDisabledValue,
      choices: getChoice(option),
      locked: option.locked,
      canEvaluate: option.canEvaluate,
      // @Wissem Note: textValueUnit contains PreEvaluationValue with value of dynamicVariable
      unitValue: [QuestionTypeEnum.ValueUnit, QuestionTypeEnum.Formula].includes(questionType) ? option.textValueUnit : null,
      placeholder: option.placeholder,
      alertData: (option.checkedAlert && OptionAlertData.mapFromApiValue(option)) || ({} as OptionAlertData),
      validationData: (option.checkedValidation && option.natureValidation && option.valueOne && OptionValidationData.mapFromApiValue(option)) || ({} as OptionValidationData),
      redirectionData: (option.checkedNextQuestion && option.nextQuestion && option.nextRubric && OptionRedirectionData.mapFromApiValue(option)) || ({} as OptionRedirectionData),
      applicationFiles: (option.applicationFiles || []).map(applicationFile => ApplicationFileLightData.mapFromApiValue(applicationFile)),
      evaluatedValue: option.evaluatedValue,
    };
  }

  export function mapToApiValue(option: QuestionOptionData<optionValueType>, questionType: QuestionTypeEnum): VersionRubricOptionRequestDTO {
    return {
      ...QuestionOptionData.setOptionValue(option, questionType, true),
      rubricOptionUuidEntity: option.uuidEntity,
    };
  }

  function getChoice(option: questionOptionResponseType): (SingleSelectItemData<string> | MultiSelectItemData)[] {
    if (option.enums) {
      return option.enums.split('__').map(value => ({ value, title: value }));
    }

    return option.preEvaluationEnums ? option.preEvaluationEnums.split('__').map(value => ({ value, title: value })) : [];
  }

  export function getOptionValue(option: questionOptionResponseType, questionType: QuestionTypeEnum): optionValueType {
    switch (questionType) {
      case QuestionTypeEnum.ShortAnswer:
      case QuestionTypeEnum.LongAnswer:
        return transformOptionsTextWithDateTimeValue(option);
      case QuestionTypeEnum.MultipleAnswers:
      case QuestionTypeEnum.ValueUnit:
        return option.textValue;

      case QuestionTypeEnum.TextEditorAnswer:
        return option.content;

      case QuestionTypeEnum.Formula:
        return option.textValue;

      case QuestionTypeEnum.Date:
        return transformOptionsDateWithDateTimeValue(option);

      case QuestionTypeEnum.DateTime:
        return DateUtils.toCalendarFormat(DateUtils.toZonedDateTime(option.dateTimeValue));

      case QuestionTypeEnum.Duration:
        return QuestionDurationData.mapFromApiValue(option);

      case QuestionTypeEnum.ListOfMultipleChoices:
        return compact((option.textValue || '').split('__'));

      case QuestionTypeEnum.ListOfUniqueChoices:
        return option.textValue;

      case QuestionTypeEnum.Location:
        return QuestionLocationData.mapFromApiValue(option);

      case QuestionTypeEnum.UniqueChoice:
      case QuestionTypeEnum.MultipleChoices:
        return option.checkedValue;

      case QuestionTypeEnum.Photo:
      case QuestionTypeEnum.File:
        return (option.applicationFiles || []).map(applicationFile => ApplicationFileLightData.mapFromApiValue(applicationFile));

      case QuestionTypeEnum.Collaborator:
        return QuestionContributorData.mapFromApiValue(option);

      default:
        break;
    }
  }

  function transformOptionsTextWithDateTimeValue(option: questionOptionResponseType): string {
    if (option.dateTimeValue) {
      return DateUtils.toDateTimeFormat(DateUtils.toZonedDateTime(option.dateTimeValue));
    }

    return option.textValue;
  }

  function transformOptionsDateWithDateTimeValue(option: questionOptionResponseType): string {
    if (option.dateTimeValue) {
      return DateUtils.toCalendarFormat(DateUtils.toZonedDateTime(option.dateTimeValue), true);
    }

    return DateUtils.toCalendarFormat(DateUtils.toZonedDateTime(option.dateValue), true);
  }

  export function checkForEmptyValue(value: optionValueType, localImages: File[], questionType: QuestionTypeEnum, required: boolean): boolean {
    if (!required) {
      if (questionType === QuestionTypeEnum.Collaborator) {
        const _value = value as QuestionContributorData;

        return _value.disableSignature ? !!(_value.selectedUser || (_value.firstName && _value.lastName && _value.function)) : !!_value.imageSignature;
      }

      return true;
    }

    switch (questionType) {
      case QuestionTypeEnum.ShortAnswer:
      case QuestionTypeEnum.LongAnswer:
      case QuestionTypeEnum.MultipleAnswers:
      case QuestionTypeEnum.ValueUnit:
      case QuestionTypeEnum.Formula:
      case QuestionTypeEnum.Date:
      case QuestionTypeEnum.DateTime:
      case QuestionTypeEnum.ListOfUniqueChoices:
      case QuestionTypeEnum.TextEditorAnswer:
        return !!value;

      case QuestionTypeEnum.Duration:
        return !!((value as QuestionDurationData).hours && (value as QuestionDurationData).minutes);

      case QuestionTypeEnum.Photo:
      case QuestionTypeEnum.File:
        return !!localImages?.length || (value as ApplicationFileLightData[]).length > 0;

      case QuestionTypeEnum.Collaborator:
        const _value = value as QuestionContributorData;

        return _value.disableSignature ? !!(_value.selectedUser || (_value.firstName && _value.lastName && _value.function)) : !!_value.imageSignature;

      case QuestionTypeEnum.ListOfMultipleChoices:
        return (value as string[]).length > 0;

      case QuestionTypeEnum.Location:
        return !!((value as QuestionLocationData).longitude && (value as QuestionLocationData).latitude);

      default:
        return true; // for unique and multi choices, user can set true or false, so he can lock even without checking any response
    }
  }

  export function setOptionValue(
    option: QuestionOptionData<optionValueType>,
    questionType: QuestionTypeEnum,
    isLockedOption?: boolean,
  ): Partial<OptionUpdateRequestDTO & OptionSignatoryUpdateRequestDTO> | Partial<VersionRubricOptionRequestData> {
    switch (questionType) {
      case QuestionTypeEnum.ShortAnswer:
      case QuestionTypeEnum.LongAnswer:
      case QuestionTypeEnum.Formula:
      case QuestionTypeEnum.MultipleAnswers:
      case QuestionTypeEnum.ValueUnit:
        return { textValue: option.value as string };

      case QuestionTypeEnum.TextEditorAnswer:
        return { content: option.value as string };

      case QuestionTypeEnum.Date:
        return { dateValue: DateUtils.toZonedDate(option.value as string) };

      case QuestionTypeEnum.DateTime:
        return { dateTimeValue: DateUtils.toZonedDateTime(option.value as string) };

      case QuestionTypeEnum.Duration:
        const time = option.value as QuestionDurationData;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { timeValue: time.hours && time.minutes && (`${time.hours}:${time.minutes}` as any) };

      case QuestionTypeEnum.ListOfMultipleChoices:
        return { textValue: ((option.value as string[]).filter(value => !isEmpty(value)) || []).join('__') };

      case QuestionTypeEnum.ListOfUniqueChoices:
        return { textValue: option.value as string };

      case QuestionTypeEnum.Location:
        return {
          altitude: (option.value as QuestionLocationData).altitude,
          latitude: (option.value as QuestionLocationData).latitude,
          longitude: (option.value as QuestionLocationData).longitude,
        };

      case QuestionTypeEnum.UniqueChoice:
      case QuestionTypeEnum.MultipleChoices:
        return { checkedValue: option.value as boolean };

      case QuestionTypeEnum.File:
      case QuestionTypeEnum.Photo:
        return isLockedOption
          ? { deletedApplicationFilesUuides: option.applicationFiles.map(file => file.uuidEntity) }
          : ({ applicationFiles: option.applicationFiles.map(file => file.uuidEntity) } as Partial<OptionUpdateRequestDTO & OptionSignatoryUpdateRequestDTO>);

      default:
        return {};
    }
  }
}
