import { SfxMap } from '../utils/enum-utils';
import { RouteIdEnum } from './router-enums/route.enum';
import { UserRightEnum } from './user-right.enum';
import { UserRoleEnum } from './user-role.enum';

export interface PermissionType {
  rights: UserRightEnum[];
  roles?: UserRoleEnum[];
  forbidden?: UserRightEnum[];
}
export type SfxPermissionObject = SfxMap<RouteIdEnum, SfxMap<PermissionActionEnum, PermissionType>>;

export enum PermissionActionEnum {
  Read = 'read',
  Add = 'add',
  Edit = 'edit',
  Duplicate = 'duplicate',
  Delete = 'delete',
  Publish = 'publish',
  Export = 'export',
  Import = 'import',
  Upload = 'upload',
  Download = 'download',
  Restore = 'restore',
  Unlock = 'unlock',
  EditStatus = 'editStatus',
  EditRef = 'editRef',
  ExecuteFlowRef = 'executeFlowRef',
  Unlink = 'unlink',
  ControlDiffusion = 'controlDiffusion',
  MultiPr = 'multiPr',
  Pr = 'Pr',
  DeleteSignature = 'deleteSignature',
  View = 'view',
  Share = 'share',
  Archive = 'archive',
  GenerateDocument = 'generateDocument',
  EditFlowContributors = 'editFlowContributors',
  Deactivate = 'deactivate',
  Navigate = 'navigate',
  Guide = 'guide',
  Reset = 'reset',
  DiagramImportFlow = 'diagramImportFlow',
}

export interface PermissionObject {
  [key: string]: PermissionType;
}

export namespace PermissionActionEnum {
  /* Settings Account */
  export const settingsModule = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  /* Settings Account */
  export const accountPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  /* Settings Client */
  export const clientPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const clientModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  /* Settings Companies */
  export const companiesPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const companiesModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal] }],
  ]);

  /* Settings Families */
  export const familyPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Archive, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const familyModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  /* Settings Categories */
  export const categoryPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Archive, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const categoryModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  /* Settings custom status */
  export const customStatusPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const customStatusModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  /* Settings references */
  export const referencePage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const referenceModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  /* Settings customField (attributes) */
  export const customFieldPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const customFieldModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  /* Settings custom file */
  export const customFilePage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const customFileModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  /* Settings Custom cell  */
  export const customCellPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const customCellModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  /* Settings folder (section)  */
  export const folderPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const folderModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  /* Settings Trash */
  export const trashPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  /* Settings general */
  export const generalPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow] }],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow] }],
  ]);

  /* Settings Trams */
  export const tramPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow] }],
  ]);

  export const tramModal = new SfxMap<PermissionActionEnum, PermissionType>([[PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow] }]]);

  /* Settings Backups */
  export const backupPage = new SfxMap<PermissionActionEnum, PermissionType>([[PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow] }]]);

  /* Settings LogsPush */
  export const logsPush = new SfxMap<PermissionActionEnum, PermissionType>([[PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow] }]]);

  /* settings general organization */
  export const settingsGeneralOrganization = new SfxMap<PermissionActionEnum, PermissionType>([[PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow] }]]);

  /* Settings KPI */
  export const kpiPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  /* Projects */
  export const projectPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible] }],
    [PermissionActionEnum.Publish, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible] }],
  ]);

  export const projectModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible] }],
  ]);

  export const addProjectModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      {
        rights: [
          UserRightEnum.AdminSiteFlow,
          UserRightEnum.AdminApp,
          UserRightEnum.AdminAppGlobal,
          UserRightEnum.NormalUser,
          UserRightEnum.UserAdvanced,
          UserRightEnum.UserContractor,
        ],
      },
    ],
  ]);

  export const fileProcessingAndPgacModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  /* Library module */
  export const libraryModule = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      {
        rights: [
          UserRightEnum.AdminSiteFlow,
          UserRightEnum.AdminApp,
          UserRightEnum.AdminAppGlobal,
          UserRightEnum.UserAdvanced,
          UserRightEnum.NormalUser,
          UserRightEnum.UserContractor,
        ],
      },
    ],
  ]);

  /* Library Risks */
  export const riskPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
    [PermissionActionEnum.Duplicate, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Archive, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const riskModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Upload, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Import, { rights: [], forbidden: UserRightEnum.contractors }],
  ]);

  export const editRiskModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  export const viewRisk = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
  ]);

  /* Library Equipments */

  export const equipmentPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
    [PermissionActionEnum.Duplicate, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Archive, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const equipmentModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Upload, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Import, { rights: [], forbidden: UserRightEnum.contractors }],
  ]);

  export const editEquipmentModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  export const viewEquipmentModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
  ]);

  /* Library Documents */

  export const documentPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.ControlDiffusion, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.UserManageDiffusion] }],
  ]);

  export const documentModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  /* Library Forms */

  export const formPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      {
        rights: [
          UserRightEnum.AdminSiteFlow,
          UserRightEnum.AdminApp,
          UserRightEnum.AdminAppGlobal,
          UserRightEnum.UserAdvanced,
          UserRightEnum.NormalUser,
          UserRightEnum.UserContractor,
        ],
      },
    ],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.UserContractor] },
    ],
    [
      PermissionActionEnum.Duplicate,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.UserContractor] },
    ],
    [
      PermissionActionEnum.Delete,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.UserContractor] },
    ],
    [PermissionActionEnum.Export, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserContractor] }],
    [PermissionActionEnum.Archive, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserContractor] }],
  ]);

  export const libraryFormModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Add, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.UserGenericForm, UserRightEnum.UserModelForm] }],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.UserGenericForm, UserRightEnum.UserModelForm] }],
  ]);

  /** Form Local Modal */
  export const formLocalModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
      },
    ],
  ]);

  /* Library Objects */

  export const objectPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const objectModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  export const generateFlowModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
  ]);

  /* Library Tags */

  export const tagsPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const tagModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const linkFlowModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
  ]);

  /* Contributor module */
  export const contributorModule = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
  ]);

  /* Contributor -> Users */

  export const userPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Download, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Export, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Restore, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Unlock, { rights: [UserRightEnum.AdminSiteFlow] }],
  ]);

  export const userModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  export const moveApplicabilityModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const userSkillsModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  /* Contributor -> external users */

  export const externalUsersPage = new SfxMap<PermissionActionEnum, PermissionType>([[PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow] }]]);

  export const externalUsersModal = new SfxMap<PermissionActionEnum, PermissionType>([[PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow] }]]);

  /* Contributor -> Operators */

  export const operatorPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Download, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Export, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Restore, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const operatorModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  export const operatorSkillsModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  /* Contributors -> Tablets */

  export const tabletPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser] },
    ],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.View, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
    [PermissionActionEnum.Unlock, { rights: [UserRightEnum.AdminSiteFlow] }],
  ]);

  export const tabletModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  /* External API */

  export const externalApiPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow] }],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow] }],
  ]);

  export const externalApiModal = new SfxMap<PermissionActionEnum, PermissionType>([[PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow] }]]);
  export const externalApiV2Modal = new SfxMap<PermissionActionEnum, PermissionType>([[PermissionActionEnum.Read, { rights: [UserRightEnum.AdminSiteFlow] }]]);

  /* Dashboard -> Flow -> List vue */

  export const flowVueListPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Delete,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Expert, UserRoleEnum.Responsible] },
    ],
    [
      PermissionActionEnum.EditStatus,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [PermissionActionEnum.EditFlowContributors, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible] }],
  ]);

  export const flowListVueModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [PermissionActionEnum.EditFlowContributors, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible] }],
  ]);

  /* Dashboard ->Flow -> Plan */

  export const flowVuePlanPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible] }],
    [
      PermissionActionEnum.Unlink,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
  ]);

  /* Dashboard ->Flow -> Diagram */

  export const flowVueDiagramPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
  ]);

  export const flowVueDiagramModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.DiagramImportFlow,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
  ]);

  /* Dashboard -> Document */

  export const dashDocumentPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Delete,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert],
        forbidden: UserRightEnum.contractors,
      },
    ],
    [PermissionActionEnum.ControlDiffusion, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.UserManageDiffusion] }],
  ]);

  export const dashDocumentModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [PermissionActionEnum.EditRef, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  export const dashDocumentImportModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert],
        forbidden: UserRightEnum.contractors,
      },
    ],
  ]);

  /* Dashboard -> Form */

  export const dashFormPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Delete,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert],
        forbidden: UserRightEnum.contractors,
      },
    ],
    [
      PermissionActionEnum.Add,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
    [
      PermissionActionEnum.Share,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
  ]);

  export const dashFromImportModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
  ]);

  export const dashFromResponseModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
      },
    ],
    [PermissionActionEnum.Edit, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [
      PermissionActionEnum.EditStatus,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
    [
      PermissionActionEnum.Share,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
  ]);

  export const dashImportFromResponsesModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.View,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Writer, UserRoleEnum.Expert] },
    ],
  ]);

  /* Dashboard -> Flow Ref */
  export const flowReferentialPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Delete,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert],
        forbidden: UserRightEnum.contractors,
      },
    ],
    [
      PermissionActionEnum.Unlock,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
    [PermissionActionEnum.EditStatus, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible] }],
  ]);

  export const flowReferentialModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
        forbidden: UserRightEnum.contractors,
      },
    ],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.ExecuteFlowRef,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [PermissionActionEnum.EditStatus, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible] }],
  ]);

  /* Dashboard -> Lists */
  export const dashboardListPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Delete,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert],
        forbidden: UserRightEnum.contractors,
      },
    ],
  ]);

  export const dashboardListModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
        forbidden: UserRightEnum.contractors,
      },
    ],
  ]);

  /* Dashboard -> Schedule tasks */
  export const dashboardScheduleTasks = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Delete,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
  ]);

  /* Dashboard -> Contributors */
  export const dashboardContributorPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Delete,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible],
        forbidden: UserRightEnum.contractors,
      },
    ],
  ]);

  export const dashboardContributorsModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert],
        forbidden: UserRightEnum.contractors,
      },
    ],
  ]);

  export const dashboardContributorsEditModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      {
        rights: [],
        forbidden: UserRightEnum.contractors,
      },
    ],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
  ]);

  /* Dashboard -> Tablets */
  export const dashboardTabletsPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible] }],
    [
      PermissionActionEnum.View,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
  ]);

  export const dashboardTabletModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
  ]);

  /* Dashboard -> Field Feedback */

  export const fieldFeedbackPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Download,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
    [
      PermissionActionEnum.EditStatus,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [
      PermissionActionEnum.Share,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.GenerateDocument,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
      },
    ],
  ]);

  /* Dashboard -> closing folder */

  export const dashboardClosingFolderPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Delete,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert],
      },
    ],
    [PermissionActionEnum.ControlDiffusion, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.UserManageDiffusion] }],
  ]);

  export const dashboardClosingFolderModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Read,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer],
      },
    ],
    [PermissionActionEnum.EditRef, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced] }],
  ]);

  /* Dashboard -> category */

  export const dashboardCategoryPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
    [
      PermissionActionEnum.Delete,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible],
        forbidden: UserRightEnum.contractors,
      },
    ],
    [
      PermissionActionEnum.Archive,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
  ]);

  export const dashboardCategoryModal = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Add,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert],
        forbidden: UserRightEnum.contractors,
      },
    ],
    [
      PermissionActionEnum.Edit,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert],
        forbidden: UserRightEnum.contractors,
      },
    ],
  ]);
  export const multiPRActionSelect = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
  ]);

  /* Flow content */

  export const flowContentPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.MultiPr,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced],
        roles: [UserRoleEnum.Expert, UserRoleEnum.Writer],
      },
    ],
    [
      PermissionActionEnum.Pr,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal, UserRightEnum.UserAdvanced],
        roles: [UserRoleEnum.Expert, UserRoleEnum.Writer],
      },
    ],
    [PermissionActionEnum.DeleteSignature, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [
      PermissionActionEnum.Navigate,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer, UserRoleEnum.Operator] },
    ],
    [
      PermissionActionEnum.Share,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
  ]);

  /* Step content */
  export const stepContentPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Reset,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
  ]);

  /* Multi bloc content */
  export const multiBlocPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Delete,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Deactivate,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Duplicate,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
  ]);

  /* Form block content */
  export const formBlockPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Navigate,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer, UserRoleEnum.Operator] },
    ],
    [
      PermissionActionEnum.Share,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Import,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Writer, UserRoleEnum.Expert] },
    ],
  ]);

  /* Form builder */

  export const formBuilderPage = new SfxMap<PermissionActionEnum, PermissionType>([
    [PermissionActionEnum.Read, { rights: [] }],
    [PermissionActionEnum.DeleteSignature, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
    [PermissionActionEnum.Unlock, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal] }],
  ]);

  /* Response Management Modal*/

  const responseManagement = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Edit,
      {
        rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal, UserRightEnum.AdminApp, UserRightEnum.UserAdvanced, UserRightEnum.NormalUser, UserRightEnum.SimpleUser],
        roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert],
      },
    ],
    [
      PermissionActionEnum.EditStatus,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert] },
    ],
    [PermissionActionEnum.Duplicate, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible] }],
    [PermissionActionEnum.Delete, { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminAppGlobal, UserRightEnum.AdminApp] }],
    [
      PermissionActionEnum.Share,
      { rights: [UserRightEnum.AdminSiteFlow, UserRightEnum.AdminApp, UserRightEnum.AdminAppGlobal], roles: [UserRoleEnum.Responsible, UserRoleEnum.Expert, UserRoleEnum.Writer] },
    ],
  ]);

  /* Block Object */
  const blockObjectImport = new SfxMap<PermissionActionEnum, PermissionType>([
    [
      PermissionActionEnum.Unlink,
      { rights: [UserRightEnum.AdminAppGlobal, UserRightEnum.AdminSiteFlow], roles: [UserRoleEnum.Expert, UserRoleEnum.Operator, UserRoleEnum.Responsible, UserRoleEnum.Writer] },
    ],
    [
      PermissionActionEnum.Edit,
      { rights: [UserRightEnum.AdminAppGlobal, UserRightEnum.AdminSiteFlow], roles: [UserRoleEnum.Expert, UserRoleEnum.Operator, UserRoleEnum.Responsible, UserRoleEnum.Writer] },
    ],
  ]);

  export const allPermissions = new SfxMap<RouteIdEnum, SfxMap<PermissionActionEnum, PermissionType>>([
    /* Settings module */
    [RouteIdEnum.Settings, settingsModule],
    /* Settings  Account */
    [RouteIdEnum.SettingsAccount, accountPage],
    /* Settings  SettingsGeneralOrganization */
    [RouteIdEnum.SettingsGeneralOrganization, settingsGeneralOrganization],
    /* Settings Client */
    [RouteIdEnum.SettingsClientBook, clientPage],
    [RouteIdEnum.SettingsClientBookAddModal, clientModal],
    [RouteIdEnum.SettingsClientBookEditModal, clientModal],
    /* Settings Companies */
    [RouteIdEnum.SettingsCompanies, companiesPage],
    [RouteIdEnum.SettingsCompaniesAddModal, companiesModal],
    [RouteIdEnum.SettingsCompaniesEditModal, companiesModal],
    /* Settings Families */
    [RouteIdEnum.SettingsFamilies, familyPage],
    [RouteIdEnum.SettingsEditFamily, familyModal],
    [RouteIdEnum.SettingsAddFamily, familyModal],
    /* Settings Categories */
    [RouteIdEnum.DefaultCategories, categoryPage],
    [RouteIdEnum.AddDefaultCategory, categoryModal],
    [RouteIdEnum.EditDefaultCategory, categoryModal],
    /* Settings statuses */
    [RouteIdEnum.CustomStatus, customStatusPage],
    [RouteIdEnum.AddCustomStatus, customStatusModal],
    [RouteIdEnum.EditCustomStatus, customStatusModal],
    /* Settings references */
    [RouteIdEnum.SettingsReferences, referencePage],
    [RouteIdEnum.ReferenceAdd, referenceModal],
    [RouteIdEnum.ReferenceEdit, referenceModal],
    /* Settings custom field (attributes) */
    [RouteIdEnum.SettingsCustomField, customFieldPage],
    [RouteIdEnum.EditCustomField, customFieldModal],
    [RouteIdEnum.AddCustomField, customFieldModal],
    /* Settings custom file */
    [RouteIdEnum.SettingsCustomFile, customFilePage],
    [RouteIdEnum.EditCustomFile, customFileModal],
    [RouteIdEnum.AddCustomFile, customFileModal],
    /* Settings custom cell */
    [RouteIdEnum.SettingsCustomCell, customCellPage],
    [RouteIdEnum.AddCustomCell, customCellModal],
    [RouteIdEnum.EditCustomCell, customCellModal],
    /* Settings folder (section) */
    [RouteIdEnum.SettingsSections, folderPage],
    [RouteIdEnum.SectionAdd, folderModal],
    [RouteIdEnum.SectionEdit, folderModal],
    [RouteIdEnum.FolderAdd, folderModal],
    [RouteIdEnum.FolderEdit, folderModal],
    /* Settings trash */
    [RouteIdEnum.SettingsTrash, trashPage],
    /* Settings general */
    [RouteIdEnum.SettingsGeneralAccount, generalPage],
    /* Settings trams */
    [RouteIdEnum.SettingsTrams, tramPage],
    [RouteIdEnum.AddTram, tramModal],
    [RouteIdEnum.EditTram, tramModal],
    /* Settings backup */
    [RouteIdEnum.SettingsBackups, backupPage],
    /* Settings logsPush */
    [RouteIdEnum.SettingsLogsPush, logsPush],
    /* Settings kpis */
    [RouteIdEnum.SettingsKpis, kpiPage],
    /* Projects */
    [RouteIdEnum.Projects, projectPage],
    [RouteIdEnum.ProjectEditModal, projectModal],
    [RouteIdEnum.ProjectAddModal, addProjectModal],
    [RouteIdEnum.PrepareFolderModal, projectModal],
    [RouteIdEnum.ProjectDuplicateModal, projectModal],
    [RouteIdEnum.ProjectFolderDuplicateModal, projectModal],
    [RouteIdEnum.ProjectFolder, projectModal],
    [RouteIdEnum.ProjectPgacModal, fileProcessingAndPgacModal],
    [RouteIdEnum.ProjectFileProcessingModal, fileProcessingAndPgacModal],
    /* Library module */
    [RouteIdEnum.Library, libraryModule],
    /* Library risks */
    [RouteIdEnum.LibraryRisks, riskPage],
    [RouteIdEnum.LibraryRisksAddDialogue, riskModal],
    [RouteIdEnum.LibraryRisksEditDialogue, editRiskModal],
    [RouteIdEnum.LibraryRisksViewDialogue, viewRisk],
    [RouteIdEnum.LibraryRisksImportDialogue, riskModal],
    [RouteIdEnum.LibraryRiskReorganizeDialog, riskModal],

    /* Library Equipments */
    [RouteIdEnum.LibraryEquipments, equipmentPage],
    [RouteIdEnum.EquipmentsAdd, equipmentModal],
    [RouteIdEnum.EquipmentsEdit, editEquipmentModal],
    [RouteIdEnum.ViewEquipments, viewEquipmentModal],
    [RouteIdEnum.EquipmentsUpload, equipmentModal],
    [RouteIdEnum.LibraryEquipmentReorganizeDialog, equipmentModal],

    /* Library Documents */
    [RouteIdEnum.LibraryDocuments, documentPage],
    [RouteIdEnum.LibraryDocumentsReorganizeModal, documentModal],
    [RouteIdEnum.LibraryDocumentsEditModal, documentModal],
    [RouteIdEnum.LibraryDocumentsAddModal, documentModal],
    /* Library Forms */
    [RouteIdEnum.LibraryForms, formPage],
    [RouteIdEnum.LibraryFormsAddModal, libraryFormModal],
    [RouteIdEnum.LibraryFormsEditModal, libraryFormModal],
    [RouteIdEnum.LibraryFormsEditLocalModal, formLocalModal],
    [RouteIdEnum.LibraryFormsDuplicateModal, libraryFormModal],
    [RouteIdEnum.LibraryFormsReorganizeModal, libraryFormModal],
    /* Library Objects */
    [RouteIdEnum.LibraryObjectManagement, objectPage],
    [RouteIdEnum.LibraryObjectManagementAddModal, objectModal],
    [RouteIdEnum.LibraryObjectManagementEditModal, objectModal],
    [RouteIdEnum.LibraryObjectManagementAssignModal, objectModal],
    [RouteIdEnum.LibraryObjectManagementDuplicateModal, objectModal],
    [RouteIdEnum.LibraryObjectManagementGenerateModal, generateFlowModal],
    /* Library Tags */
    [RouteIdEnum.LibraryTags, tagsPage],
    [RouteIdEnum.LibraryTagsAddDialog, tagModal],
    [RouteIdEnum.LibraryTagsEditDialog, tagModal],
    [RouteIdEnum.LibraryTagLinksDialog, linkFlowModal],
    /* Contributor module */
    [RouteIdEnum.Contributors, contributorModule],
    /* Contributor -> Users  */
    [RouteIdEnum.ContributorUsers, userPage],
    [RouteIdEnum.UserAddModal, userModal],
    [RouteIdEnum.UserEditModal, userModal],
    [RouteIdEnum.UsersQuickConnectionModal, userModal],
    [RouteIdEnum.UserReorganizeModal, userModal],
    [RouteIdEnum.UserUploadModal, userModal],
    [RouteIdEnum.UserMoveApplicabilityModal, moveApplicabilityModal],
    [RouteIdEnum.UserSkillModal, userSkillsModal],
    /* Contributor -> operators */
    [RouteIdEnum.ContributorExternalUsers, externalUsersPage],
    [RouteIdEnum.EditExternalUser, externalUsersModal],
    /* Contributor -> operators */
    [RouteIdEnum.ContributorOperators, operatorPage],

    [RouteIdEnum.OperatorAddModal, operatorModal],
    [RouteIdEnum.OperatorEditModal, operatorModal],
    [RouteIdEnum.OperatorReorganizeModal, operatorModal],
    [RouteIdEnum.OperatorUploadModal, operatorModal],
    [RouteIdEnum.OperatorMoveApplicabilityModal, moveApplicabilityModal],
    [RouteIdEnum.OperatorSkillsModal, operatorSkillsModal],
    /* Contributor -> Tablets */
    [RouteIdEnum.ContributorTablets, tabletPage],
    [RouteIdEnum.AddTabletModal, tabletModal],
    [RouteIdEnum.EditTabletModal, tabletModal],
    /* External API */
    [RouteIdEnum.contributorExternalAPI, externalApiPage],
    [RouteIdEnum.AddAccessApiModal, externalApiModal],
    [RouteIdEnum.EditAccessApiModal, externalApiModal],
    [RouteIdEnum.AddAccessApiV2Modal, externalApiV2Modal],
    [RouteIdEnum.EditAccessApiV2Modal, externalApiV2Modal],
    /* Dashboard -> flow -> vue list */
    [RouteIdEnum.FlowManagement, flowVueListPage],
    [RouteIdEnum.FlowManagementAddModal, flowListVueModal],
    [RouteIdEnum.FlowManagementEditModal, flowListVueModal],
    [RouteIdEnum.FlowManagementDuplicateModal, flowListVueModal],
    [RouteIdEnum.FlowManagementMoveModal, flowListVueModal],
    [RouteIdEnum.FlowManagementGeoMapModal, flowListVueModal],
    [RouteIdEnum.FlowManagementContributorsModal, flowListVueModal],
    [RouteIdEnum.ProjectPgacDetailsModal, flowListVueModal],
    [RouteIdEnum.FlowManagementPgacDuplicateModal, flowListVueModal],
    [RouteIdEnum.FlowManagementReorganizeModal, flowListVueModal],
    /* Dashboard -> flow -> plan */
    [RouteIdEnum.FlowManagementPlan, flowVuePlanPage],
    [RouteIdEnum.DiagramImportFlow, flowVueDiagramModal],
    /* Dashboard -> flow -> Diagram */
    [RouteIdEnum.FlowManagementDiagram, flowVueDiagramPage],
    /* Dashboard -> Document */
    [RouteIdEnum.DashboardDocuments, dashDocumentPage],
    [RouteIdEnum.DashboardDocumentsImportFromLibraryDialog, dashDocumentImportModal],
    [RouteIdEnum.DashboardDocumentsReorganizeDialog, dashDocumentModal],
    [RouteIdEnum.DashDocumentsEditModal, dashDocumentModal],
    [RouteIdEnum.DashDocumentsAddModal, dashDocumentModal],
    /* Dashboard -> Form */
    [RouteIdEnum.DashboardForms, dashFormPage],
    [RouteIdEnum.DashboardFormsImportModal, dashFromImportModal],
    [RouteIdEnum.DashboardFormsReplyModal, dashFromResponseModal],
    [RouteIdEnum.DashboardFormsResponseModal, dashFromResponseModal],
    [RouteIdEnum.ImportFormResponsesModal, dashImportFromResponsesModal],
    /* Dashboard -> Flow Ref */
    [RouteIdEnum.DashboardFlowReferential, flowReferentialPage],
    [RouteIdEnum.DashboardFlowReferentialEditModal, flowReferentialModal],
    [RouteIdEnum.DashboardFlowReferentialManagementImportModal, flowReferentialModal],
    [RouteIdEnum.FlowManagementExecuteModal, flowReferentialModal],
    [RouteIdEnum.FlowManagementExecuteListModal, flowReferentialModal],
    [RouteIdEnum.DashboardFlowPgacDetailsModal, flowReferentialModal],
    /* Dashboard -> Lists */
    [RouteIdEnum.DashboardLists, dashboardListPage],
    [RouteIdEnum.DashboardListAddModal, dashboardListModal],
    [RouteIdEnum.DashboardListDialogGeneralTabModal, dashboardListModal],
    [RouteIdEnum.DashboardListDialogImportationTabModal, dashboardListModal],

    /* Dashboard -> Schedule Tasks */
    [RouteIdEnum.DashboardScheduleTasks, dashboardScheduleTasks],

    /* Dashboard -> Contributors */
    [RouteIdEnum.DashboardContributors, dashboardContributorPage],
    [RouteIdEnum.DashboardContributorEditModal, dashboardContributorsEditModal],
    [RouteIdEnum.DashboardCollaboratorsReorganizeModal, dashboardContributorsModal],
    [RouteIdEnum.DashboardCollaboratorsImportModal, dashboardContributorsModal],
    /* Dashboard -> Tablets */
    [RouteIdEnum.DashboardTablets, dashboardTabletsPage],
    [RouteIdEnum.DashboardTabletsImportModal, dashboardTabletModal],
    [RouteIdEnum.DashboardTabletsQuickConnectionModal, dashboardTabletModal],
    /* Dashboard field feedback */
    [RouteIdEnum.DashboardFieldFeedback, fieldFeedbackPage],
    /* Dashboard -> closing folder */
    [RouteIdEnum.DashboardClosingFolder, dashboardClosingFolderPage],
    [RouteIdEnum.DashboardEditSection, dashboardClosingFolderModal],
    [RouteIdEnum.DashboardAddSection, dashboardClosingFolderModal],
    [RouteIdEnum.DashboardAddFolder, dashboardClosingFolderModal],
    [RouteIdEnum.DashboardEditFolder, dashboardClosingFolderModal],
    [RouteIdEnum.DashboardVerificationSection, dashboardClosingFolderModal],
    /* Dashboard -> category */
    [RouteIdEnum.DashboardCategories, dashboardCategoryPage],
    [RouteIdEnum.DashboardEditCategory, dashboardCategoryModal],
    [RouteIdEnum.DashboardAddCategory, dashboardCategoryModal],
    /* Flow content */
    [RouteIdEnum.FlowContent, flowContentPage],
    /* Step content */
    [RouteIdEnum.BlockPrContent, stepContentPage],
    /* Multi bloc content */
    [RouteIdEnum.BlockMultiPrContent, multiBlocPage],
    /* Form block content */
    [RouteIdEnum.BlockFormContent, formBlockPage],
    /* Form builder */
    [RouteIdEnum.Form, formBuilderPage],
    /* Response Management Modal*/
    [RouteIdEnum.ResponseManagementModal, responseManagement],
    /* multi pr action select */
    [RouteIdEnum.MultiPRActionSelect, multiPRActionSelect],
    /* flow content > edit flow */
    [RouteIdEnum.EditFlowContentModal, flowListVueModal],
    /* Flow content > Import from library */
    [RouteIdEnum.ImportEmmModal, equipmentModal],
    [RouteIdEnum.ImportAdrModal, riskModal],
    /* Flow -> Block Object */
    [RouteIdEnum.BlockObjectImportModal, blockObjectImport],
    [RouteIdEnum.BlockObjectEditModal, blockObjectImport],
    [RouteIdEnum.BlockObjectContent, blockObjectImport],
  ]);
}
