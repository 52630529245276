import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { intersection } from 'lodash';

import { PermissionActionEnum, PermissionType, RouteIdEnum, SfxPermissionObject, UserRightEnum, UserRoleEnum, UserRoleType } from '../../../core/enums';
import { SfxMap } from '../../../core/utils/enum-utils';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private permissions$ = new BehaviorSubject<SfxPermissionObject>(new SfxMap());
  private userRights$ = new BehaviorSubject<UserRightEnum[]>([]);
  private userRoles$ = new BehaviorSubject<UserRoleType>({});

  public loadPermissions(): void {
    this.permissions$.next(PermissionActionEnum.allPermissions);
  }

  public flushPermissions(): void {
    this.permissions$.next(new SfxMap());
  }

  public flushRoles(): void {
    this.userRights$.next([]);
    this.userRoles$.next({});
  }

  public addUserRights(roles: UserRightEnum[]): void {
    this.userRights$.next([...this.userRights$.value, ...roles]);
  }

  public addUserRoles(uuidEntity: string, roles: UserRoleEnum[]): void {
    this.userRoles$.next({ ...this.userRoles$.value, [uuidEntity]: roles });
  }

  public checkUserRight(routeId: RouteIdEnum, permissionAction?: PermissionActionEnum): Observable<boolean> {
    return this.userRights$.pipe(
      filter(roles => !!roles.length),
      withLatestFrom(this.permissions$.pipe(map(permissions => permissions.getValue(routeId)?.getValue(permissionAction || PermissionActionEnum.Read)))),
      map(([rights, permissions]) => !this.isUserForbidden(permissions.forbidden, rights) && this.evaluateUserRightsOrRoles(permissions?.rights, rights)),
    );
  }

  public checkUserRole(routeId: RouteIdEnum, uuidEntityContext: string, permissionAction: PermissionActionEnum): Observable<boolean> {
    return this.userRoles$.pipe(
      withLatestFrom(
        this.userRights$,
        this.permissions$.pipe(map(permissions => (permissions.has(routeId) ? permissions.getValue(routeId).getValue(permissionAction) : undefined))),
      ),
      map(([userRoles, userRights, permissions]) => this.evaluatePermissions(userRoles, userRights, permissions, uuidEntityContext)),
    );
  }

  public evaluatePermissions(userRoles: UserRoleType, userRights: UserRightEnum[], permissions: PermissionType, uuidEntityContext: string) {
    if (!userRights || !permissions) {
      return true;
    }
    const isUserForbidden = this.isUserForbidden(permissions.forbidden, userRights);
    if (isUserForbidden) {
      return false;
    }
    const contextRoles = userRoles[uuidEntityContext];
    const requiredRoles = permissions.roles;
    const shouldCheckRoles = requiredRoles && contextRoles?.length;

    this.evaluateUserRightsOrRoles(permissions.forbidden, userRights);

    return shouldCheckRoles ? this.evaluateUserRightsOrRoles(permissions.roles, contextRoles) : this.evaluateUserRightsOrRoles(permissions.rights, userRights);
  }

  public evaluateUserRightsOrRoles<T extends UserRightEnum | UserRoleEnum>(required: T[], user: T[]) {
    return !required?.length || !!intersection(required, user).length;
  }

  public isUserForbidden(forbiddenRights: UserRightEnum[], userRights: UserRightEnum[]) {
    return forbiddenRights?.length && !!intersection(forbiddenRights, userRights).length;
  }
}
