import { RouteParamEnum } from '../enums/router-enums/route-prams.enum';

export namespace RouterPaths {
  // root paths
  export namespace RootPaths {
    export const rootPath = '';
    export const mainPath = 'explore';
    export const notFoundPath = '404';
    export const callbackPath = 'callback';
    export const unregisteredPath = '**';
  }

  // Dialog paths
  export namespace DialogPaths {
    export const dialogPath = 'dialog';
    export const projectDialogPath = 'project';
    export const flowDialogPath = 'flow';
    export const planDialogPath = 'plan';
    export const diagramDialogPath = 'diagram';
    export const referentialFlowDialogPath = 'flow-referential';
    export const riskDialogPath = 'risk';
    export const tagsDialogPath = 'tag';
    export const objectDialogPath = 'object';
    export const categoryDialogPath = 'category';
    export const defaultCategoryDialogPath = 'default-category';
    export const tabletsDialogPath = 'tablets';
    export const dashboardTabletsDialogPath = 'dashboard-tablets';
    export const userDialogPath = 'users';
    export const operatorDialogPath = 'operator';
    export const libraryFormDialogPath = 'form-lib';
    export const dashboardFromDialogPath = 'dashboard-form';
    export const dashboardFieldFeedbackDialogPath = 'dashboard-field-feedback';
    export const equipmentDialogPath = 'equipment';
    export const familyDialogPath = 'family';
    export const clientDialogPath = 'client';
    export const flowContentDialogPath = 'flow-content';
    export const blockDialogPath = 'blocks';
    export const blockAdrDialogPath = 'block-adr';
    export const blockEmmDialogPath = 'block-emm';
    export const blockDsiDialogPath = 'block-dsi';
    export const blockRhpDialogPath = 'block-rhp';
    export const blockMultiPrDialogPath = 'block-multi-pr';
    export const blockFormDialogPath = 'block-form';
    export const blockObjectDialogPath = 'block-object';
    export const tramDialogPath = 'tram';
    export const accessApiDialogPath = 'access-api';
    export const accessApiV2DialogPath = 'access-api-v2';
    export const customStatusDialogPath = 'custom-status';
    export const customFieldDialogPath = 'custom-field';
    export const referenceDialogPath = 'references';
    export const customFileDialogPath = 'custom-files';
    export const customCellDialogPath = 'custom-cells';
    export const folderDialogPath = 'folders';
    export const wordingConfigurationsDialogPath = 'wordingConfiguration';
    export const collaboratorsDialogPath = 'collaborators';
    export const videoTutorialDialogPath = 'video-tutorial';
    export const listsDialogPath = 'list';
    export const quickConnectionDialogPath = `quick-connection/:${RouteParamEnum.ElementUuidEntity}`;
    export const dashDocumentsDialogPath = 'dashboard-documents';
    export const closingFolderDialogPath = 'closing-folder';
    export const sectionDialogPath = 'section';
    export const libDocumentDialogPath = 'library-document';
    export const linkedTags = 'linkedTags';
    export const formDialogPath = 'form';
    export const prepareFolderDialogPath = `prepareFolderDialog/:${RouteParamEnum.ElementUuidEntity}`;
    export const shareDialogPath = 'share';
    export const externalUserDialogPath = 'external-user';
    export const GeneralOrganizationApplicationsDialogPath = 'general-organization-applications';
    export const unlockFlowPath = `unlock-flow/:${RouteParamEnum.ElementUuidEntity}`;
    export const companiesDialogPath = 'company';
  }

  // common
  export namespace CommonPaths {
    export const addPath = 'add';
    export const importPath = 'import';
    export const uploadPath = 'upload';
    export const reorganizePath = 'reorganize';
    export const customizeColumnsPath = 'customize-columns';
    export const viewPath = `view/:${RouteParamEnum.ElementUuidEntity}`;
    export const editPath = `edit/:${RouteParamEnum.ElementUuidEntity}`;
    export const convertPath = `convert/:${RouteParamEnum.ElementUuidEntity}`;
    export const detailsPath = `details/:${RouteParamEnum.ElementUuidEntity}`;
    export const duplicatePath = `duplicate/:${RouteParamEnum.ElementUuidEntity}`;
    export const duplicateFolderPath = `duplicateFolder/:${RouteParamEnum.ElementUuidEntity}`;
    export const editFolderPath = `edit-folder/:${RouteParamEnum.ElementUuidEntity}`;
    export const movePath = `move/:${RouteParamEnum.ElementUuidEntity}`;
    export const schedulePath = `schedule/:${RouteParamEnum.ElementUuidEntity}`;
    export const teamRoleAffectationPath = `team-role-affectation/:${RouteParamEnum.FlowUuidEntity}`;
    export const teamRoleAffectationEditPath = `team-role-affectation-edit/:${RouteParamEnum.FlowUuidEntity}`;
    export const propertiesInfoPath = `properties/:${RouteParamEnum.ElementUuidEntity}`;
    export const historyPath = `history/:${RouteParamEnum.ElementUuidEntity}`;
    export const addWithParamsPath = `add/:${RouteParamEnum.ElementUuidEntity}`;
    export const skillsPath = `skills/:${RouteParamEnum.ElementUuidEntity}`;
    export const selectedElementPath = `:${RouteParamEnum.ElementUuidEntity}`;
    export const verificationPath = `verification/:${RouteParamEnum.ElementUuidEntity}`;
    export const list = 'list';
    export const collaboratorsPath = `collaborators/:${RouteParamEnum.ElementUuidEntity}`;
  }

  // tab paths
  export namespace TabPaths {
    export const generalTabPath = 'general-tab';
    export const attributeTabPath = 'attribute-tab';
    export const optionsTabPath = 'options-tab';
    export const importTabPath = 'import-tab';
    export const contentTabPath = 'content-tab';
  }

  // Entry paths
  export namespace EntryPaths {
    export const loginPath = 'auth';
    export const resetPwdPath = 'reset-password';
    export const signUpPath = 'sign-up/ca70bddd663847ae9e8cfdb9b8694e9b';
    export const resetPasswordConfirmationPath = 'reset/finish';
    export const onboardingPath = 'onboarding/finish';
    export const activatePath = 'activate';
  }

  // Share paths
  export namespace SharePaths {
    export const rootPath = `share/:${RouteParamEnum.ShareUuidEntity}`;
    export const rootGeneratedPath = `share/:${RouteParamEnum.ShareUuidEntity}/:${RouteParamEnum.ApplicationFileUuidEntity}`;
    export const dialogPath = `share/:${RouteParamEnum.BusinessClass}/:${RouteParamEnum.ElementUuidEntity}`;
    export const fblResponseDialogPath = `share/:${RouteParamEnum.BusinessClass}/:${RouteParamEnum.ElementUuidEntity}/:${RouteParamEnum.FormBusinessLinkUuidEntity}`;
    export const loginPath = 'login';
    export const initFormPath = 'init-form';
    export const initDocPath = 'init-document';
    export const contributorInfoPath = 'contributor-info';
    export const finishPath = `finish/:${RouteParamEnum.ElementUuidEntity}`;
    export const finishAndContinueLaterPath = `finishAndContinueLater/:${RouteParamEnum.ElementUuidEntity}`;
  }

  // Project paths
  export namespace ProjectPaths {
    export const projectsPath = 'projects';
    export const pgacPath = 'pgac';
    export const fileProcessingPath = 'file-processing';
  }

  // contributor paths
  export namespace CollaboratorPaths {
    export const collaboratorsPath = 'collaborators';
    export const usersPath = 'users';
    export const operatorsPath = 'operators';
    export const externalUsersPath = 'external-users';
    export const tabletsPath = 'tablets';
    export const externalApiPath = 'external-api';
    export const externalApiV1Path = 'v1';
    export const externalApiV2Path = 'v2';
  }

  // library paths
  export namespace LibraryPaths {
    export const libraryPath = 'library';
    export const risksPath = 'risks';
    export const equipmentsPath = 'equipments';
    export const formsPath = 'forms';
    export const documentsPath = 'documents';
    export const tagsPath = 'tags';
    export const objectManagementPath = 'object-management';
  }

  // settings paths
  export namespace SettingsPaths {
    export const settingsPath = 'settings';
    export const accountPath = 'account';
    export const clientsPath = 'clients';
    export const companiesPath = 'companies';
    export const familiesPath = 'families';
    export const categoriesPath = 'categories';
    export const statusesPath = 'statuses';
    export const customFilesPath = 'files-models';
    export const customCellsPath = 'files-cells';
    export const referencesPath = 'references';
    export const customFieldsPath = 'custom-fields';
    export const sectionsPath = 'sections';
    export const generalAccountPath = 'general-account';
    export const generalOrganizationPath = 'general-organization';
    export const tramsPath = 'trams';
    export const wordingConfigurationPath = 'wording-configuration';
    export const editLanguageConfigPath = `edit-language/:${RouteParamEnum.ElementUuidEntity}`;
    export const addLanguageConfigPath = `add/:${RouteParamEnum.ParentUuidEntity}`;
    export const backupsPath = 'backups';
    export const logsPushPath = 'logsPushPath';
    export const kpiPath = 'kpi';
    export const trashPath = 'trash';
    export const editSectionPath = `edit-section/:${RouteParamEnum.ElementUuidEntity}`;
  }

  // profile paths
  export namespace ProfilePaths {
    export const profilePath = 'profile';
    export const userInfoPath = 'user-info';
    export const resetPasswordPath = 'reset-password';
    export const tabletPath = 'tablet';
  }

  export namespace ProjectContentPaths {
    export const projectDetailsPath = `:${RouteParamEnum.ProjectUuidEntity}`;
    export const projectFolder = `folder/:${RouteParamEnum.FolderUuidEntity}`;
  }

  // dashboard paths
  export namespace DashboardPaths {
    export const dashboardPath = 'dashboard';
    export const flowManagementPath = 'flow-management';
    export const vueListPath = 'vue-list';
    export const flowMapPath = 'vue-map';
    export const flowDiagramPath = 'vue-diagram';
    export const flowKanbanPath = 'vue-kanban';
    export const planPath = 'vue-plan';
    export const documentsPath = 'documents';
    export const flowReferentialPath = 'flow-referential';
    export const formsPath = 'forms';
    export const listsPath = 'lists';
    export const scheduleTasksPath = 'schedule-tasks';
    export const collaboratorsPath = 'collaborators';
    export const tabletsPath = 'tablets';
    export const metricsPath = 'metrics';
    export const signaturesPath = 'signatures';
    export const signatureEmptyPath = '...';
    export const signatureTemporalPath = 'vue-temporal';
    export const fieldFeedbackPath = 'fields-feedback';
    export const closingFolderPath = 'closing-folder';
    export const informationPath = 'information';
    export const categoriesPath = 'categories';
  }

  // Flow content
  export namespace FlowContentPaths {
    export const revisionPath = 'revision';

    export const validationPath = 'validation';
    export const resetBlocStatusPath = 'reset-bloc-status';
    export const historyPath = 'history';
    export const formHistoryPath = `form-history/:${RouteParamEnum.ElementUuidEntity}`;
    export const historyMultiPrPath = 'multi-pr-history';
    export const orphanResponsePath = 'orphan-responses';
    export const propertiesPath = 'properties';
    export const editFlowContentPath = 'edit';
    export const blockContentPath = 'blocks';
    export const blockContentMobilePath = 'mobile-vue';
    export const imageEditorPath = `image-editor/:${RouteParamEnum.ElementUuidEntity}`;
    export const blockContentMobileByBlockUuidPath = `:${RouteParamEnum.BlockUuidEntity}`;
    export const managementPath = `management/:${RouteParamEnum.ElementUuidEntity}/:${RouteParamEnum.FormUuidEntity}`;
    export const replaceFormPath = `replace/:${RouteParamEnum.ElementUuidEntity}`;
    export const editSignaturePath = `edit-signature/:${RouteParamEnum.ElementUuidEntity}`;
    export const signPath = `sign/:${RouteParamEnum.SignatureNature}`;
    export const signPathByElementUuidPath = `sign/:${RouteParamEnum.SignatureNature}/:${RouteParamEnum.ElementUuidEntity}`;
    export const editSignByElementUuidPath = `edit-signature/:${RouteParamEnum.ElementUuidEntity}/:${RouteParamEnum.NodeOrder}`;
    export const signByElementUuidPath = `sign/:${RouteParamEnum.ElementUuidEntity}/:${RouteParamEnum.NodeOrder}`;
    export const navigatePath = `navigate/:${RouteParamEnum.ElementUuidEntity}`;
    export const addPhasePath = `add-phase/:${RouteParamEnum.NodeOrder}`;
    export const editPhasePath = `edit-phase/:${RouteParamEnum.PhaseUuidEntity}`;
    export const addStepPath = `add-step/:${RouteParamEnum.PhaseUuidEntity}/:${RouteParamEnum.NodeOrder}`;
    export const editStepPath = `edit-step/:${RouteParamEnum.PhaseUuidEntity}/:${RouteParamEnum.StepElementUuidEntity}`;
    export const flowContentPath = `flow/:${RouteParamEnum.FlowUuidEntity}`;
    export const stepContentPath = `step/:${RouteParamEnum.StepUuidEntity}`;
    export const blockRhpContentPath = `rhp/:${RouteParamEnum.BlockUuidEntity}`;
    export const blockEmmContentPath = `emm/:${RouteParamEnum.BlockUuidEntity}`;
    export const blockAdrContentPath = `adr/:${RouteParamEnum.BlockUuidEntity}`;
    export const blockDsiContentPath = `dsi/:${RouteParamEnum.BlockUuidEntity}`;
    export const blockTxtContentPath = `txt/:${RouteParamEnum.BlockUuidEntity}`;
    export const blockFormContentPath = `form/:${RouteParamEnum.BlockUuidEntity}`;
    export const blockPrContentPath = `pr/:${RouteParamEnum.BlockUuidEntity}`;
    export const blockMultiPrContentPath = `multi-pr/:${RouteParamEnum.BlockUuidEntity}`;
    export const blockObjectContentPath = `object/:${RouteParamEnum.BlockUuidEntity}`;
    export const editObservationPath = `edit-observation/:${RouteParamEnum.ElementUuidEntity}`;
    export const addObservationPath = `add-observation/:${RouteParamEnum.ElementUuidEntity}`;
    export const sequencePath = 'sequence';
    export const sequenceFromPhasePath = `sequence/:${RouteParamEnum.PhaseUuidEntity}`;
    export const flowHistory = 'flow-history-item';
    export const importFormResponsesPath = `import-form-responses/:${RouteParamEnum.ElementUuidEntity}`;
  }

  // Flow management
  export namespace FlowManagementDialogPaths {
    export const pgacDuplicateManagementPath = 'duplicate-management';
    export const geoMapPath = 'geo-map-management';
    export const executePath = `execute/:${RouteParamEnum.ElementUuidEntity}`;
    export const executeListPath = 'execute-list';
  }

  // Plan
  export namespace PlanDialogPaths {
    export const importFlowPath = 'flows';
    export const linkFlowPath = 'link';
    export const importTeamRolePath = 'team-roles';
    export const addMileStone = 'addMileStone';
    export const editMileStone = `editMileStone/:${RouteParamEnum.ElementUuidEntity}`;
  }

  // Diagram
  export namespace DiagramDialogPaths {
    export const flowDiagramPath = `flow-diagram/:${RouteParamEnum.ElementUuidEntity}`;
  }

  // Object managements paths
  export namespace ObjectManagementPaths {
    export const treeVuePath = 'tree-vue';
    export const listVuePath = 'list-vue';
    export const mapVuePath = 'map-vue';
    export const assignPath = `assign/:${RouteParamEnum.ElementUuidEntity}`;
    export const generateFlowPath = `generate-flow/:${RouteParamEnum.ElementUuidEntity}`;
  }

  // Form paths
  export namespace FormPaths {
    export const basePath = `form/:${RouteParamEnum.FormUuidEntity}`;
    export const byProjectPath = `project/:${RouteParamEnum.ProjectUuidEntity}`;
    export const byProjectAndFblPath = `project/:${RouteParamEnum.ProjectUuidEntity}/fbl/:${RouteParamEnum.FormBusinessLinkUuidEntity}`;
    export const byProjectFlowAndFblPath = `project/:${RouteParamEnum.ProjectUuidEntity}/flow/:${RouteParamEnum.FlowUuidEntity}/fbl/:${RouteParamEnum.FormBusinessLinkUuidEntity}`;
    export const rubricPath = `rubric/:${RouteParamEnum.RubricUuidEntity}`;
    export const addRubricPath = `add-rubric/:${RouteParamEnum.ElementUuidEntity}`;
    export const editRubricPath = `edit-rubric/:${RouteParamEnum.ElementUuidEntity}`;
    export const editQuestionPath = `edit-question/:${RouteParamEnum.ElementUuidEntity}`;
    export const editOptionPath = `edit-option/:${RouteParamEnum.QuestionUuidEntity}/:${RouteParamEnum.ElementUuidEntity}`;
    export const formSummaryPath = `summary/:${RouteParamEnum.ElementUuidEntity}`;
    export const formSummaryFblPath = `summary/:${RouteParamEnum.ElementUuidEntity}/:${RouteParamEnum.FormBusinessLinkUuidEntity}`;
    export const editLocalFormPath = `edit-local/:${RouteParamEnum.ElementUuidEntity}`;
    export const formResponsesListPath = `responses-list/:${RouteParamEnum.ElementUuidEntity}`;
    export const replyFormPath = `reply/:${RouteParamEnum.ElementUuidEntity}/:${RouteParamEnum.FormUuidEntity}`;
    export const rubricGalleryPath = `rubric-gallery/:${RouteParamEnum.ElementUuidEntity}`;
    export const questionGalleryPath = `question-gallery/:${RouteParamEnum.ElementUuidEntity}`;
    // Note: Added questionUuid to route
    export const optionGalleryPath = `option-gallery/:${RouteParamEnum.ElementUuidEntity}/:${RouteParamEnum.QuestionUuidEntity}`;
    export const annotateRubricImagePath = `annotate-rubric-image/:${RouteParamEnum.ElementUuidEntity}`;
    export const annotateQuestionImagePath = `annotate-question-image/:${RouteParamEnum.ElementUuidEntity}`;
    export const annotateOptionImagePath = `annotate-option-image/:${RouteParamEnum.ElementUuidEntity}`;
    export const annotateQuestionOptionImagePath = `annotate-option-image/:${RouteParamEnum.QuestionUuidEntity}/:${RouteParamEnum.ElementUuidEntity}`;
    export const annotateOptionTmpImagePath = `annotate-option-tmp-image/:${RouteParamEnum.ElementUuidEntity}/:${RouteParamEnum.NodeOrder}`;
    export const viewImagePath = `view-image/:${RouteParamEnum.ElementUuidEntity}`;
    export const viewTmpImagePath = `view-tmp-image/:${RouteParamEnum.ElementUuidEntity}/:${RouteParamEnum.NodeOrder}`;
    export const questionChangesHistory = `changes-history/:${RouteParamEnum.ElementUuidEntity}`;
  }

  export namespace ListPaths {
    export const editEmmPath = `edit-emm/:${RouteParamEnum.ElementUuidEntity}`;
    export const editAdrPath = `edit-adr/:${RouteParamEnum.ElementUuidEntity}`;
  }
}

export const sfxModalOutlet = 'modals';
