import { createAction, props } from '@ngrx/store';

import { FieldNameEnum } from '../../../../../../../../core/enums';
import {
  ApplicationEventLightData,
  BlockFormHistoryData,
  BlockFormResponseData,
  BlockFormResponseSummaryData,
  BlockFormRubricLightData,
  BlockFormRubricsRestrictionData,
  BlockFormToImportSummaryData,
  LazyLoadResponse,
  SelectAllData,
} from '../../../../../../../../core/models';
import { ReplaceFormData } from '../../../../../../../../core/models/block-form/block-form-replace-data';
import { FormCheckResponseData } from '../../../../../../../../core/models/form-response/form-check-response-data';
import { DialogToasterData } from '../../../../../../../sfx-dialog/state/dialog-toaster-data';

// Reply dialog actions
export const initializeBlockFormResponse = createAction(
  '[bFormDialog] initialize form response',
  props<{ formResponse: BlockFormResponseData; initialReference: string; formCheckResponseData: FormCheckResponseData }>(),
);
export const checkResponseInProgressFail = createAction('[bFormDialog] check responses in progress fail', props<DialogToasterData>());

export const replyBlockForm = createAction('[bFormDialog] reply form', props<{ response: BlockFormResponseData }>());
export const replyBlockFormFail = createAction('[bFormDialog] reply form fail', props<DialogToasterData>());
export const replyBlockFormSuccess = createAction(
  '[bFormDialog] reply form success',
  props<{ responseUuidEntity: string; formBusinessLinkUuidEntity: string; rubricOrder: number }>(),
);

export const initializeBlockFormInitialRubric = createAction('[bFormDialog] initialize form initial rubric', props<{ initialRubric: BlockFormRubricLightData }>());
export const getBlockFormInitialRubricFail = createAction('[bFormDialog] get form initial rubric fail', props<DialogToasterData>());

export const guide = createAction('[bFormDialog] guide form', props<{ rubricUuidType: string; rubricUuidEntity: string }>());
export const guideFormConfirm = createAction('[bFormDialog] guide form confirm', props<{ rubricUuidType: string }>());
export const guideFail = createAction('[bFormDialog] guide form fail', props<DialogToasterData>());

// Import dialog actions
export const loadBlockForms = createAction('[bFormDialog] load forms');
export const loadBlockFormsFail = createAction('[bFormDialog] load forms fail', props<DialogToasterData>());
export const loadBlockFormsSuccess = createAction(
  '[bFormDialog] load forms success',
  props<{ forms: BlockFormToImportSummaryData[]; totalCount: number; filteredTotalCount: number; disabledFilteredTotalCount: number; reset?: boolean }>(),
);
export const loadMoreBlockForms = createAction('[bFormDialog] load more forms');

export const importBlockForms = createAction('[bFormDialog] import forms', props<{ selectAllData: SelectAllData }>());
export const importBlockFormsFail = createAction('[bFormDialog] import forms fail', props<DialogToasterData>());
export const importBlockFormsSuccess = createAction('[bFormDialog] import forms success');

// response dialog actions
export const loadResponses = createAction('[bFormDialog] load responses list');
export const loadResponsesFail = createAction('[bFormDialog] load responses fail', props<DialogToasterData>());
export const loadResponsesSuccess = createAction(
  '[bFormDialog] load responses success',
  props<{ responses: BlockFormResponseSummaryData[]; totalCount: number; filteredTotalCount: number; reset?: boolean }>(),
);
export const loadMoreResponses = createAction('[bFormDialog] load more responses');
export const loadImportableFormResponses = createAction('[bFormDialog] load importable form responses', props<{ reset: boolean }>());

export const importFormResponses = createAction('[bFormDialog] import form responses', props<{ selectAllData: SelectAllData }>());
export const importFormResponsesFail = createAction('[bFormDialog] import form responses fail', props<DialogToasterData>());
export const importFormResponsesSuccess = createAction('[bFormDialog] import form responses success');

export const openFormResponseFilterDatePopover = createAction('[bFormDialog] open form response filter date popover', props<{ origin: HTMLElement }>());

export const openExportXlsFilePopover = createAction('[bFormDialog] open xsl file popover', props<{ origin: HTMLElement }>());
export const exportExcelFile = createAction('[bFormDialog] export responses in excel file', props<{ onlyExportableValues: boolean; customFileUuidEntity?: string }>());
export const exportExcelFileSuccess = createAction('[bFormDialog] export responses in excel file success');
export const exportExcelFileFail = createAction('[bFormDialog] export responses in excel file fail', props<DialogToasterData>());

export const exportImageFile = createAction('[bFormDialog] export responses in image file ');
export const exportImageFileSuccess = createAction('[bFormDialog] export responses in image file success');
export const exportImageFileFail = createAction('[bFormDialog] export responses in image file fail', props<DialogToasterData>());

export const overviewResponse = createAction('[bFormDialog] overview response', props<{ response: BlockFormResponseSummaryData }>());
export const editResponse = createAction('[bFormDialog] edit response', props<{ response: BlockFormResponseSummaryData }>());

export const openEditResponseReference = createAction('[bFormDialog] open edit response reference', props<{ response: BlockFormResponseSummaryData; origin: HTMLElement }>());

export const setEditResponseReferenceLoading = createAction('[bFormDialog] set edit response reference loading', props<{ loading: boolean }>());
export const editResponseReferenceFail = createAction('[bFormDialog] edit response reference fail', props<DialogToasterData>());

export const setSelectedResponses = createAction('[bFormDialog] set selected responses', props<{ responsesUuidEntity: string[] }>());

export const openGenerateDocument = createAction('[bFormDialog] open generate document', props<{ response: BlockFormResponseSummaryData; origin: HTMLElement }>());
export const openGenerateDocumentFail = createAction('[bFormDialog] open generate document fail', props<DialogToasterData>());

export const generateDocumentFail = createAction('[bFormDialog] generate document from response fail', props<DialogToasterData>());

export const openGenerateMultipleDocument = createAction('[bFormDialog] open generate multiple document', props<{ origin: HTMLElement }>());

export const checkGenerateDocumentHasRefGenerated = createAction(
  '[bFormDialog] check generate document has generated reference',
  props<{ labelFamilyUuidEntity: string; fieldName: FieldNameEnum }>(),
);
export const checkGenerateDocumentHasRefGeneratedFail = createAction('[bFormDialog] check generate document has generated reference fail', props<DialogToasterData>());
export const checkGenerateDocumentHasRefGeneratedSuccess = createAction(
  '[bFormDialog] check generate document has generated reference success',
  props<{ generateDocumentHasRefGenerated: boolean; fieldName: FieldNameEnum }>(),
);

export const displayDocumentFail = createAction('[bFormDialog] display document fail', props<DialogToasterData>());
export const setGenerateDocumentLoading = createAction('[bFormDialog] set generate document loading', props<{ loading: boolean }>());

export const resetResponseState = createAction('[bFormDialog] reset response state', props<{ responseUuidEntity: string }>());
export const resetResponseStateFail = createAction('[bFormDialog] reset response state fail', props<DialogToasterData>());

export const duplicateResponse = createAction('[bFormDialog] duplicate response', props<{ responseUuidEntity: string }>());
export const duplicateResponseSuccess = createAction('[bFormDialog] duplicate response success');
export const duplicateResponseFail = createAction('[bFormDialog] duplicate response fail', props<DialogToasterData>());

export const deleteResponse = createAction('[bFormDialog] delete response', props<{ responseUuidEntity: string; formBusinessLinkUuidEntity: string }>());
export const deleteResponseFail = createAction('[bFormDialog] delete response fail', props<DialogToasterData>());

export const openEditResponseStatus = createAction('[bFormDialog] open edit response status', props<{ response: BlockFormResponseSummaryData; origin: HTMLElement }>());

export const setEditResponseStatusLoading = createAction('[bFormDialog] set edit response status loading', props<{ loading: boolean }>());
export const editResponseStatusFail = createAction('[bFormDialog] edit response status fail', props<DialogToasterData>());

// History dialog actions
export const loadFormHistory = createAction('[bFormDialog] load form history');
export const loadMoreFormHistory = createAction('[bFormDialog] load more form history');
export const loadFormHistoryFail = createAction('[bFormDialog] load form history fail', props<DialogToasterData>());
export const loadFormHistorySuccess = createAction('[bFormDialog] load form history success', props<{ formEvents: LazyLoadResponse<BlockFormHistoryData[]>; reset?: boolean }>());

// replace form dialog actions
export const initializeReplaceForm = createAction('[bFormDialog] initialize replace form', props<{ replaceForm: ReplaceFormData }>());
export const replaceForm = createAction('[bFormDialog] replace form', props<{ form: ReplaceFormData }>());
export const replaceFormFail = createAction('[bFormDialog] replace form fail', props<DialogToasterData>());
export const replaceFormSuccess = createAction('[bFormDialog] replace form success');
export const cancelReplace = createAction('[bFormDialog] replace form cancel');

export const loadHistoryListSuccess = createAction('[bFormDialog] Load history list success', props<{ data: LazyLoadResponse<ApplicationEventLightData[]>; reset?: boolean }>());
export const loadHistoryListNextPage = createAction('[bFormDialog] Load history list next page');
export const loadHistoryListFails = createAction('[bFormDialog] Load history list fails', props<DialogToasterData>());

export const loadRubricsToRestrict = createAction('[bFormDialog] load block form rubrics list to restrict');
export const loadRubricsToRestrictSuccess = createAction(
  '[bFormDialog] load block form rubrics list to restrict success',
  props<{ rubricsToRestrict: BlockFormRubricsRestrictionData[] }>(),
);
export const loadRubricsToRestrictFail = createAction('[bFormDialog] load block form rubrics list to restrict Failed', props<DialogToasterData>());
export const restrictRubrics = createAction('[bFormDialog] restrict rubric', props<{ rubrics: BlockFormRubricsRestrictionData[] }>());
export const restrictAllRubrics = createAction('[bFormDialog] bulk restrict all rubrics', props<{ rubrics: BlockFormRubricsRestrictionData[] }>());
